.top {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  align-items: center; 
}
.liveby-logo {
  height: 2.75em;
  padding: 1em;
  width: 10.07em;
}
.btn.logout {
  color: #555555;
  font-weight: bold;
  border: none;
  border: solid 1px #DDD;
  background-color: #EEE;
  padding: .5em 1em;
  border-radius: 2px;
  cursor: pointer;
}