.visible-tab .title {
  text-align: center;
  margin: 0 45px 30px 30px;
  color: #878787;
  font-size: 36px;
}

.visible-tab {
  display: flex;
  flex-flow: column;
}

.select-communities {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.city-info {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 10px 0;
}

.city-info span {
  color: #B5B5B5;
  font-size: 10px;
  font-weight: 500;
}

.community-search {
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 13px 0;
}

.community-search input {
  border: 1px solid #C2C2C2;
  border-radius: 17px;
  font-size: 12px;
  padding: 7px 0px 7px 32px;
  min-width: 250px;
}

.select-section {
  display: flex;
  flex-flow: column;
  max-width: 50em;
  min-height: 80vh;
  width: 40em;
}

.select-section .title {
  margin: 0 0 1em;
  text-align: center;
  font-weight: 600;
  color: #4b4b60;
}

.lookup .label,
.lookup-community .label {
  text-transform: uppercase;
  color: #666;
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: .5em;
}

.lookup-community .lookup-type .community-types {
  border: 1px solid #ddd;
  height: 7.5em;
  overflow: auto;
}

.lookup-community .lookup-type .community-types > label {
  display: block;
  padding: .25em;
}

.lookup-community .search-button {
  display: inline-block;
  border-radius: 3px;
  background: #fff;
  font-size: 1em;
  font-weight: normal;
  padding: .5em 1em;
  margin-top: .5em;
}

.lookup-community .search-button:hover {
  cursor: pointer;
  background-color: #ddd;
}

.select {
  flex: 1;
}

.select input {
  color: #4b4b60;
}

.select-section .communities-header {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  margin-top: 2em;
}

.select-section .communities-header .city-name {
  margin: 0;
}

.select-section .community-list {
  padding: 0 1em;
}

.community-list {
  height: 22.5em;
  list-style: none;
  margin: 1em 0;
  overflow: auto;
  padding: 0;
  flex-grow: 1;
}

.community-list .empty {
  font-size: 1.5em;
  color: #B5B5B5;
  text-align: center;
  margin-top: 1em;
}

.community-list-item {
  margin: .5em 0;
  border-radius: 2px;
  background-color: #FFFFFF;
  border: 2px solid #4b4b60;
  padding: 1em .75em;
  cursor: pointer;
}

.community-list-item svg,
.community-list-item .label,
.community-list-item .type {
  vertical-align: middle;
}

.community-list-item .label {
  margin-left: 1em;
}

.community-list-item .type {
  float: right;
}

.community-list-item.selected {
  border-color: #89CC89;
  background-color: hsla(0,0%,100%,.2);
}

.select-section .city-name .amount {
  color: #fff;
  border-radius: 10px;
  margin-left: 0.8333333333em;
  background-color: #626278;
  text-align: center;
  font-size: 0.75em;
  padding: 0.3333333333em 0.8333333333em 0.1666666667em;
  vertical-align: middle;
}

.select-section .multi-toggle {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: #B5B5B5;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  padding: 0;
}

.select-section .finish {
  margin: 0 auto;
  text-align: center;
}

.select-section .finish .commit {
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: auto;
  font-size: 1em;
  margin: 0 auto;
  padding: 1em 2em;
  text-align: center;
  transition: background-color .4s, color .4s;
}

.select-section .finish .submit {
  background-color: #20a1d5;
  border: none;
}

.select-section .finish .cancel {
  border: 1px solid #B5B5B5;
  background-color: #fff;
  color: #B5B5B5;
  margin-right: 1em;
}

.select-section .finish .cancel:hover {
  background-color: #B5B5B5;
  color: #fff;
}

.select-section .neighborhood-container {
  padding: 0 .5em;
  margin: 1em 0;
}

.select-section .neighborhood-container input {
  color: #4b4b60;
  background-color: #fff;
  border: 1px solid #B5B5B5;
  transition: border .2s;
}

.select-section input:focus,
.select-section input:hover {
  outline: none;
}

.select-section .search-icon {
  height: 1.5em;
  left: 1.5em;
}

.select-section .search-icon g g path,
.select-section .search-icon g g + path{
  fill: #B5B5B5;
  transition: fill .2s;
}

.select-section input:focus + .search-icon g g path,
.select-section input:focus + .search-icon g g + path{
  fill: #4b4b60;
}

.brokerage-preview {
  padding: 0em 2.5em 0;
  display: flex;
  flex-flow: column;
  /* background-color: #F5F5F5; */
  overflow: auto;
  position: relative;
  height: 100%;
}

.brokerage-preview .header {
  color: #878787;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 11px 0;
}

.brokerage-preview .neighborhood-container {
  /* padding: 0 .625em; */
}

.brokerage-preview input {
  color: #4b4b60;
  background-color: #fff;
  border: 2px solid #B5B5B5;
  transition: border .2s;
  width: calc(100% - 3em);
}

.brokerage-preview input:focus,
.brokerage-preview input:hover {
  outline: none;
  border-color: #4b4b60;
}

.brokerage-preview .search-icon {
  height: 1.5em;
  left: 1.5em;
}

.brokerage-preview .search-icon g g path,
.brokerage-preview .search-icon g g + path{
  fill: #B5B5B5;
  transition: fill .2s;
}

.brokerage-preview input:focus + .search-icon g g path,
.brokerage-preview input:focus + .search-icon g g + path{
  fill: #4b4b60;
}



.city-filter,
.last-pane {
  flex: 1 0 15em;
  padding: 2em 2.5em 0;
}

.cities-filter-list-heading {
  color: #B5B5B5;
  font-size: 1.125em;
  margin-bottom: .5em;
  margin-top: 6.75em;
  font-weight: 400;
}

.cities-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pages-list .page{
  margin-bottom: .5em;
  cursor: pointer;
}

.city-item:first-child {
  border: none;
}

.city-filter .Select .Select-control {
  border-color: #20a1d5;
}

.city-filter .Select .Select-placeholder {
  color: #fff;
  background-color: #20a1d5;
}

.city-filter .Select .Select-arrow {
  border-color: #fff transparent transparent;
}

.Select .Select-placeholder {
  color: #878787;
}

.city-filter .city-name {
  color: #4b4b60;
  font-size: .875em;
  font-weight: 600;
  display: inline-block;
  max-width: 15em;
  width: 100%;
}

.city-filter .city-name input {
  margin: 0 .5em 0 0;
}

.city-filter .amount-active {
  color: #4b4b60;
  float: right;
  font-size: .75em;
  margin-top: .375em;
}

.pages-list {
  list-style: none;
  margin: 2em 0 0 0;
  padding: 0 1em 0 0;
  overflow: auto;
}

.pages-list.open {
  margin-bottom: 5em;
}
/* 
.pages-list {
  width: 100%;
  border: none;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: .625em;
  overflow: auto;
} */

.pages-list .city-name,
.select-section .city-name {
  color: #4b4b60;
  font-size: 1em;
  font-weight: 600;
  margin: 2em 0 0;
}

/* Will need to be addressed when page type "directory" is a reality*/
.pages-list .city-item:first-of-type .city-name {
  margin: 0;
}

.pages-list .page .page-info{
  display: inline-block;
}

.pages-list .page a {
  font-size: 1em;
  border: 1px solid lightgrey;
  vertical-align: center;
  margin-bottom: .5em;
  transition: color .2s, border-color .2s;
  color: #828282;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: .75em 1em .75em 2em;
  text-decoration: none;
}

.pages-list .page a:hover {
  color: #4b4b60;
  border-color: #4b4b60;
}

.pages-list .page.selected a {
  border: 2px solid  #828282;
  background-color: hsla(0,0%,100%,.2);
}

.pages-list .page.published a {
  box-shadow: inset 4px 0 0 rgba(34,180,70,0.75);
}

.city-filter .pages-list .page span {
  line-height: 19px;
}

.pages-list .page svg {
  width: 1em;
  height: 1em;
  margin: 0 0 0 1em;
}

.city-filter .communities-list:nth-last-child() a{
  border: none;
}

.customer-pages-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.brokerage-preview .commit {
  display: none;
}

.brokerage-preview .commit.active {
  display: block;
}

.brokerage-preview .commit.active button {
  border: none;
  background-color: #C5CBD1;
  color: #FFFFFF;
  border-radius: 2px;
  padding: 10px 20px;
  user-select: none;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.page-filter {
  display: flex;
  justify-content: space-around;
  height: 500px;
  padding: 0;
  flex: 0 0 30px;
  margin: 0;
  list-style-type: none;
}

.page-filter li {
  flex: 1 1 auto;
}

.page-filter li button {
  appearance: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  height: 100%;
  padding: 2em auto;
  text-align: center;
  transition: background-color .2s;
}

.page-filter li.active button,
.page-filter li.active button:hover {
  background-color: #4C4D61;
  color: #fff;
  transition: background .4s, color .4s;
}

.page-filter li button:hover {
  background-color: #B5B5B5;
  color: #fff;
}


/* Manage actions styling */

.brokerage-preview .manage-controls {
  margin-top: -1em;
  padding: .5em 2.125em;
}

.brokerage-preview .manage-controls .manage-btn:hover,
.brokerage-preview .manage-controls .cancel-btn {
  background-color: #626279;
  border-radius: 3px;
  color: #fff;
}

.brokerage-preview .many-actions.open {
  position: absolute;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  left: 3.5em;
  right: 3.5em;
  width: auto;
}

.brokerage-preview .many-actions .selected-count {
  background-color: #78CFE6;
  color: #FFFFFF;
  padding: .75em 0;
  font-size: 0.875em;
  font-weight: 100;
}

.brokerage-preview .selected-count .amount {
  padding: .25em .5em;
  background-color: rgba(0,0,0,.15);
  border-radius: 3px;
  font-size: 1em;
  margin-right: .75em;
  margin-left: 2em;
  font-weight: 600;
}

.brokerage-preview .many-actions .actions {
  display: flex;
  align-content: center;
  justify-content: space-around;
  padding: 0 3em;
}

.brokerage-preview .many-actions .btn {
  padding: 1em;
  font-size: .875em;
  color: #757575;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.0);
  background: none;
  cursor: pointer;
}
.brokerage-preview .many-actions .btn:hover {
  border: 1px solid #20a1d5;
}
.brokerage-preview .many-actions .btn img, .many-actions .btn svg {
  margin-right: 9px;
  vertical-align: middle;
}

.brokerage-preview .many-actions.closed {
  height: 0;
  overflow: hidden;
}


/* Input placeholder values */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #B5B5B5;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #B5B5B5;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #B5B5B5;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B5B5B5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #B5B5B5;
}

@media only screen and (max-width: 414px) {
  .pages-list .page .page-info{
    display: block;
  }
}
