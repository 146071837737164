html, body, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.neighborhood-container {
  margin: 0 0 1.5em 0;
  position: relative;
}

.neighborhood-input {
  border-radius: 100px;
  padding: .75em 0 .75em 3.5em;
  width: 100%;
  font-weight: 300;
}

.neighborhood-container input {
  background-color: #626279;
  border: none;
  color: #fff;
  display: block;
  font-size: 16px;
}

.search-icon {
  position: absolute;
  height: 1.25em;
  width: 1.25em;
  top: .75em;
  left: .75em;
}
.top {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  align-items: center; 
}
.liveby-logo {
  height: 2.75em;
  padding: 1em;
  width: 10.07em;
}
.btn.logout {
  color: #555555;
  font-weight: bold;
  border: none;
  border: solid 1px #DDD;
  background-color: #EEE;
  padding: .5em 1em;
  border-radius: 2px;
  cursor: pointer;
}
.visible-tab .title {
  text-align: center;
  margin: 0 45px 30px 30px;
  color: #878787;
  font-size: 36px;
}

.visible-tab {
  display: flex;
  flex-flow: column;
}

.select-communities {
  display: flex;
  justify-content: space-around;
  flex: 1 1;
}

.city-info {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 10px 0;
}

.city-info span {
  color: #B5B5B5;
  font-size: 10px;
  font-weight: 500;
}

.community-search {
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 13px 0;
}

.community-search input {
  border: 1px solid #C2C2C2;
  border-radius: 17px;
  font-size: 12px;
  padding: 7px 0px 7px 32px;
  min-width: 250px;
}

.select-section {
  display: flex;
  flex-flow: column;
  max-width: 50em;
  min-height: 80vh;
  width: 40em;
}

.select-section .title {
  margin: 0 0 1em;
  text-align: center;
  font-weight: 600;
  color: #4b4b60;
}

.lookup .label,
.lookup-community .label {
  text-transform: uppercase;
  color: #666;
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: .5em;
}

.lookup-community .lookup-type .community-types {
  border: 1px solid #ddd;
  height: 7.5em;
  overflow: auto;
}

.lookup-community .lookup-type .community-types > label {
  display: block;
  padding: .25em;
}

.lookup-community .search-button {
  display: inline-block;
  border-radius: 3px;
  background: #fff;
  font-size: 1em;
  font-weight: normal;
  padding: .5em 1em;
  margin-top: .5em;
}

.lookup-community .search-button:hover {
  cursor: pointer;
  background-color: #ddd;
}

.select {
  flex: 1 1;
}

.select input {
  color: #4b4b60;
}

.select-section .communities-header {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  margin-top: 2em;
}

.select-section .communities-header .city-name {
  margin: 0;
}

.select-section .community-list {
  padding: 0 1em;
}

.community-list {
  height: 22.5em;
  list-style: none;
  margin: 1em 0;
  overflow: auto;
  padding: 0;
  flex-grow: 1;
}

.community-list .empty {
  font-size: 1.5em;
  color: #B5B5B5;
  text-align: center;
  margin-top: 1em;
}

.community-list-item {
  margin: .5em 0;
  border-radius: 2px;
  background-color: #FFFFFF;
  border: 2px solid #4b4b60;
  padding: 1em .75em;
  cursor: pointer;
}

.community-list-item svg,
.community-list-item .label,
.community-list-item .type {
  vertical-align: middle;
}

.community-list-item .label {
  margin-left: 1em;
}

.community-list-item .type {
  float: right;
}

.community-list-item.selected {
  border-color: #89CC89;
  background-color: hsla(0,0%,100%,.2);
}

.select-section .city-name .amount {
  color: #fff;
  border-radius: 10px;
  margin-left: 0.8333333333em;
  background-color: #626278;
  text-align: center;
  font-size: 0.75em;
  padding: 0.3333333333em 0.8333333333em 0.1666666667em;
  vertical-align: middle;
}

.select-section .multi-toggle {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: #B5B5B5;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  padding: 0;
}

.select-section .finish {
  margin: 0 auto;
  text-align: center;
}

.select-section .finish .commit {
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: auto;
  font-size: 1em;
  margin: 0 auto;
  padding: 1em 2em;
  text-align: center;
  transition: background-color .4s, color .4s;
}

.select-section .finish .submit {
  background-color: #20a1d5;
  border: none;
}

.select-section .finish .cancel {
  border: 1px solid #B5B5B5;
  background-color: #fff;
  color: #B5B5B5;
  margin-right: 1em;
}

.select-section .finish .cancel:hover {
  background-color: #B5B5B5;
  color: #fff;
}

.select-section .neighborhood-container {
  padding: 0 .5em;
  margin: 1em 0;
}

.select-section .neighborhood-container input {
  color: #4b4b60;
  background-color: #fff;
  border: 1px solid #B5B5B5;
  transition: border .2s;
}

.select-section input:focus,
.select-section input:hover {
  outline: none;
}

.select-section .search-icon {
  height: 1.5em;
  left: 1.5em;
}

.select-section .search-icon g g path,
.select-section .search-icon g g + path{
  fill: #B5B5B5;
  transition: fill .2s;
}

.select-section input:focus + .search-icon g g path,
.select-section input:focus + .search-icon g g + path{
  fill: #4b4b60;
}

.brokerage-preview {
  padding: 0em 2.5em 0;
  display: flex;
  flex-flow: column;
  /* background-color: #F5F5F5; */
  overflow: auto;
  position: relative;
  height: 100%;
}

.brokerage-preview .header {
  color: #878787;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 11px 0;
}

.brokerage-preview .neighborhood-container {
  /* padding: 0 .625em; */
}

.brokerage-preview input {
  color: #4b4b60;
  background-color: #fff;
  border: 2px solid #B5B5B5;
  transition: border .2s;
  width: calc(100% - 3em);
}

.brokerage-preview input:focus,
.brokerage-preview input:hover {
  outline: none;
  border-color: #4b4b60;
}

.brokerage-preview .search-icon {
  height: 1.5em;
  left: 1.5em;
}

.brokerage-preview .search-icon g g path,
.brokerage-preview .search-icon g g + path{
  fill: #B5B5B5;
  transition: fill .2s;
}

.brokerage-preview input:focus + .search-icon g g path,
.brokerage-preview input:focus + .search-icon g g + path{
  fill: #4b4b60;
}



.city-filter,
.last-pane {
  flex: 1 0 15em;
  padding: 2em 2.5em 0;
}

.cities-filter-list-heading {
  color: #B5B5B5;
  font-size: 1.125em;
  margin-bottom: .5em;
  margin-top: 6.75em;
  font-weight: 400;
}

.cities-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pages-list .page{
  margin-bottom: .5em;
  cursor: pointer;
}

.city-item:first-child {
  border: none;
}

.city-filter .Select .Select-control {
  border-color: #20a1d5;
}

.city-filter .Select .Select-placeholder {
  color: #fff;
  background-color: #20a1d5;
}

.city-filter .Select .Select-arrow {
  border-color: #fff transparent transparent;
}

.Select .Select-placeholder {
  color: #878787;
}

.city-filter .city-name {
  color: #4b4b60;
  font-size: .875em;
  font-weight: 600;
  display: inline-block;
  max-width: 15em;
  width: 100%;
}

.city-filter .city-name input {
  margin: 0 .5em 0 0;
}

.city-filter .amount-active {
  color: #4b4b60;
  float: right;
  font-size: .75em;
  margin-top: .375em;
}

.pages-list {
  list-style: none;
  margin: 2em 0 0 0;
  padding: 0 1em 0 0;
  overflow: auto;
}

.pages-list.open {
  margin-bottom: 5em;
}
/* 
.pages-list {
  width: 100%;
  border: none;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: .625em;
  overflow: auto;
} */

.pages-list .city-name,
.select-section .city-name {
  color: #4b4b60;
  font-size: 1em;
  font-weight: 600;
  margin: 2em 0 0;
}

/* Will need to be addressed when page type "directory" is a reality*/
.pages-list .city-item:first-of-type .city-name {
  margin: 0;
}

.pages-list .page .page-info{
  display: inline-block;
}

.pages-list .page a {
  font-size: 1em;
  border: 1px solid lightgrey;
  vertical-align: center;
  margin-bottom: .5em;
  transition: color .2s, border-color .2s;
  color: #828282;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: .75em 1em .75em 2em;
  text-decoration: none;
}

.pages-list .page a:hover {
  color: #4b4b60;
  border-color: #4b4b60;
}

.pages-list .page.selected a {
  border: 2px solid  #828282;
  background-color: hsla(0,0%,100%,.2);
}

.pages-list .page.published a {
  box-shadow: inset 4px 0 0 rgba(34,180,70,0.75);
}

.city-filter .pages-list .page span {
  line-height: 19px;
}

.pages-list .page svg {
  width: 1em;
  height: 1em;
  margin: 0 0 0 1em;
}

.city-filter .communities-list:nth-last-child() a{
  border: none;
}

.customer-pages-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.brokerage-preview .commit {
  display: none;
}

.brokerage-preview .commit.active {
  display: block;
}

.brokerage-preview .commit.active button {
  border: none;
  background-color: #C5CBD1;
  color: #FFFFFF;
  border-radius: 2px;
  padding: 10px 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.page-filter {
  display: flex;
  justify-content: space-around;
  height: 500px;
  padding: 0;
  flex: 0 0 30px;
  margin: 0;
  list-style-type: none;
}

.page-filter li {
  flex: 1 1 auto;
}

.page-filter li button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  height: 100%;
  padding: 2em auto;
  text-align: center;
  transition: background-color .2s;
}

.page-filter li.active button,
.page-filter li.active button:hover {
  background-color: #4C4D61;
  color: #fff;
  transition: background .4s, color .4s;
}

.page-filter li button:hover {
  background-color: #B5B5B5;
  color: #fff;
}


/* Manage actions styling */

.brokerage-preview .manage-controls {
  margin-top: -1em;
  padding: .5em 2.125em;
}

.brokerage-preview .manage-controls .manage-btn:hover,
.brokerage-preview .manage-controls .cancel-btn {
  background-color: #626279;
  border-radius: 3px;
  color: #fff;
}

.brokerage-preview .many-actions.open {
  position: absolute;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  left: 3.5em;
  right: 3.5em;
  width: auto;
}

.brokerage-preview .many-actions .selected-count {
  background-color: #78CFE6;
  color: #FFFFFF;
  padding: .75em 0;
  font-size: 0.875em;
  font-weight: 100;
}

.brokerage-preview .selected-count .amount {
  padding: .25em .5em;
  background-color: rgba(0,0,0,.15);
  border-radius: 3px;
  font-size: 1em;
  margin-right: .75em;
  margin-left: 2em;
  font-weight: 600;
}

.brokerage-preview .many-actions .actions {
  display: flex;
  align-content: center;
  justify-content: space-around;
  padding: 0 3em;
}

.brokerage-preview .many-actions .btn {
  padding: 1em;
  font-size: .875em;
  color: #757575;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.0);
  background: none;
  cursor: pointer;
}
.brokerage-preview .many-actions .btn:hover {
  border: 1px solid #20a1d5;
}
.brokerage-preview .many-actions .btn img, .many-actions .btn svg {
  margin-right: 9px;
  vertical-align: middle;
}

.brokerage-preview .many-actions.closed {
  height: 0;
  overflow: hidden;
}


/* Input placeholder values */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #B5B5B5;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #B5B5B5;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #B5B5B5;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B5B5B5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #B5B5B5;
}

@media only screen and (max-width: 414px) {
  .pages-list .page .page-info{
    display: block;
  }
}

/**
 * General styles for the page content editor goes here
 */
 .page-editor {
  margin: 1em;
}
.content-width {
  display: inline-block;
}

.page-editor > form {
  display: flex;
  flex-flow: row;
}

.page-editor .column-1 {
  max-width: 40em;
  width: 90%;
}

.page-editor .back-to-pages {
  color: #8f91a0;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-decoration: none;
  position: relative;
  vertical-align: top;
}

.page-editor .back-to-pages::before {
  content: "";
  border: 1px solid #babcc8;
  border-width: 0 2px 2px 0;
  display: inline-block;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  height: 6px;
  width: 6px;
  position: absolute;
  left: -8px;
  bottom: 2px;
}

.page-editor h2 {
  font-size: 1.8333333333em;
  color: #354052;
  font-weight: 400;
  margin: 0.9090909091em 0.9090909091em 0;
 }

.page-editor h3 {
  color: #7F8FA4;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: .5em;
}

.page-editor .bio-editor {
  line-height: 21px;
  font-family: inherit;
  height: 14.75em;
  overflow: auto;
  border-radius: 4px;
}

.page-editor input[type="text"],
.page-editor textarea {
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  padding: .5em;
  width: 30em;
}
.page-editor select {
  font-size: 14px;
}

.tag-editor {
  max-width: 30em;
}

.page-editor .image-alt-container {
  display: inline;
  margin-bottom: 12px;
}

.page-editor .image-alt-container  .image-preview-container {
  padding: 8px;
  border: 1px solid #dfe3e9;
}

.page-editor .image-alt-container .image-preview-container.photo-gallery {
  margin: 0 16px 0 0;
}

.page-editor .alt-text-link {
  padding-left: 11px;
  cursor: pointer;
  font-size: 12px;
  color: blue;
  margin-bottom: 12px;
}

.alt-text-header {
  text-align: right;
}

.alt-text-header a {
  padding: 8px;
  opacity: .6;
  cursor: pointer;
}

.alt-text-body {
  padding: 36px 0 0 0;
}

.alt-text-image img {
  max-width: 400px;
}

.page-editor .live-url {
  display: inline-block;
  color: #8f91a0;
  text-decoration: none;
  font-weight: 300;
  border-radius: 3px;
  background: #fafafa;
  padding: 0.5em;
  border-radius: 4px;
}

.child-pages-editor {
  font-family: inherit;
  width: 100%;
}
.page-editor .child-pages {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  max-height: 24.5em;
  overflow: auto;
}

.child-pages-editor option,
.page-editor .child-pages li {
  padding: .125em;
  font-size: 16px;
}

.page-editor .child-pages a {
  color: #333;
  text-decoration: none;
}

.page-editor .save-button {
  background-color: #20a1d5;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  padding: 1em 2em;
  text-align: center;
  transition: background-color .4s,color .4s;
  -webkit-appearance: none;
}

.page-editor .save-button:hover,
.page-editor .save-button:focus {
  box-shadow: inset 0 0 10em rgba(255, 255, 255, .15);
}

.page-editor .save-button:disabled {
  background-color: #ddd;
  border: 1px solid #ccc;
}
.page-editor .image-placeholder {
  width: 5.5555555556em;
  height: 3.1388888889em;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  color: #8F91A0;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 100;
}

.page-editor .image-placeholder.allowed {
  border: 3px solid #89CC89;
}

.page-editor .image-placeholder.allowed p {
  color: #89CC89;
}

.page-editor .image-placeholder.rejected {
  border: 3px solid #F36;
}

.page-editor .image-placeholder.rejected p {
  color: #F36;
}

.page-editor .photo-editor {
  font-size: 14px;
  line-height: 21px;
  font-family: inherit;
  width: 100%;
  height: 5em;
  overflow: auto;
  border-radius: 4px;
  padding: .25em;
  margin-bottom: 1em;
}

.page-editor .helptext {
  color: #B0B0B1;
  font-size: 10px;
  margin-bottom: .3em;
  display: block;
}

.page-editor .photo-gallery {
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 0.5em .5em 0;
}
.page-editor .photo-gallery .image-placeholder {
  width: 100%;
  height: 100%;
}

.page-editor input[type='file']:focus + .image-placeholder {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

.page-editor input[type='file'] {
  opacity: 0;
  width: 0px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-editor .image-constraints {
  color: #B0B0B1;
  float: right;
  font-size: 0.8333333333em;
  margin-top: .3em;
}

.page-editor .image-constraints.image-constraints-reached {
  color: red;
}

.page-editor .image-constraints:nth-of-type(2n),
.page-editor .image-constraints:nth-of-type(2n + 1) {
  margin-left: 1em;
}

.page-editor .photo-tagline {
  margin-bottom: .5em;
}

.page-editor .image-preview {
  width: 16.6666666667em;
  height: 9.4166666667em;
  border-radius: 4px;
  transition: background .4s ease-in-out;
}

.page-editor .photo-grid {
  display: flex;
  flex-wrap: wrap;

}

.photo-gallery .image-preview {
  display: block;
  height: 5em;
  width: auto;
}
.page-editor .image-preview-container.photo-gallery {
  width: auto;
  margin: 0 0.5em .5em 0;
  height: unset;
}


.page-editor .image-preview-container {
  position: relative;
  width: 16.6666666667em;
  height: 9.4166666667em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

}

.page-editor .edit-icon path {
  fill: red;
}

.page-editor .edit-icon {
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 2em;
  height: 2em;
  opacity: 0;
  transition: opacity .4s ease-in-out;
}

.page-editor .image-preview-container:hover {
  cursor: pointer;
}

.page-editor .image-preview-container:hover .image-preview {
  background-color: rgba(0,0,0,.5);
}

.image-preview-container:hover .edit-icon {
  opacity: 1;
}


.tags-editor {
  font-size: 14px;
  font-family: inherit;
  color: #626279;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: .5em;
}

/*
* React-Select CSS Overrides
*/

.Select-control {
  height: 37px !important;
}

.Select--multi .Select-value {
  background-color: #EEEFF0 !important;
  border: none !important;
  border-radius: 3px !important;
  color: #354052 !important;
  font-size: 14px !important;
  margin-left: 3px !important;
  margin-top: 3px !important;
  font-weight: 600 !important;
}

.Select--multi .Select-value-label {
  padding: 5px 5px 5px 10px !important;
}

.Select--multi .Select-value-icon {
  border-right: none !important;
  color: #C5CBD1 !important;
  float: right !important;
  font-size: 16px !important;
  line-height: 29px !important;
  padding: 0px 10px 0 0px !important;
  vertical-align: middle !important;
}


.page-editor .image-placeholder {
  width: 5.5555555556em;
  height: 3.1388888889em;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  color: #8f91a0;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 100
}

.page-editor .image-placeholder.allowed {
  border: 3px solid #89cc89
}

.page-editor .image-placeholder.allowed p {
  color: #89cc89
}

.page-editor .image-placeholder.rejected {
  border: 3px solid #f36
}

.page-editor .image-placeholder.rejected p {
  color: #f36
}

.page-editor .photo-editor {
  font-size: 14px;
  line-height: 21px;
  font-family: inherit;
  width: 100%;
  height: 5em;
  overflow: auto;
  border-radius: 4px;
  padding: .25em;
  margin-bottom: 1em
}

.page-editor .helptext {
  color: #b0b0b1;
  font-size: 10px;
  margin-bottom: .3em;
  display: block
}

.page-editor .photo-gallery {
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 .5em .5em 0
}

.page-editor .photo-gallery .image-placeholder {
  width: 100%;
  height: 100%
}

.page-editor input[type=file]:focus+.image-placeholder {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

.page-editor input[type=file] {
  opacity: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0
}

.page-editor .image-constraints {
  color: #b0b0b1;
  float: right;
  font-size: .8333333333em;
  margin-top: .3em
}

.page-editor .image-constraints.image-constraints-reached {
  color: red
}

.page-editor .image-constraints:nth-of-type(2n),.page-editor .image-constraints:nth-of-type(odd) {
  margin-left: 1em
}

.page-editor .photo-tagline {
  margin-bottom: .5em
}

.page-editor .image-preview {
  width: 16.6666666667em;
  height: 9.4166666667em;
  border-radius: 4px;
  transition: background .4s ease-in-out
}

.page-editor .photo-grid {
  display: flex;
  flex-wrap: wrap
}

.photo-gallery .image-preview {
  display: block;
  height: 5em;
  width: auto
}

.page-editor .image-preview-container.photo-gallery {
  width: auto;
  margin: 0 .5em .5em 0;
  height: unset
}

.page-editor .image-preview-container {
  position: relative;
  width: 16.6666666667em;
  height: 9.4166666667em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px
}


/* LiveBy Loading Icon */
.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@-webkit-keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
  }
}

/* LiveBy Login Styles */

.login {
  height: 100%;
  background-color: #DDDDDD;
}
.login .liveby-logo {
  margin: 0 auto;
  display: block;
}
.login .login-form {
  margin: 0 auto;
  display: block;
  background-color: white;
  max-width: 21em;
  min-width: 5em;
  padding: 1em 2em;
  border-radius: 2px;
  box-shadow: 2px 4px 5px #ccc;
}
.login .login-form label, .login .login-form input {
  display: block;
  margin: .5em 0;
}
.login .login-form input {
  width: 90%;
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
}

.login .login-form .btn {
  width: 5em;
  color: #555555;
  font-weight: bold;
  border: none;
  border: solid 1px #DDD;
  background-color: #EEE;
  padding: .5em 1em;
  border-radius: 2px;
  cursor: pointer;
}
.login .error {
  color: #F27574;
}
.login .sent {

}
