/* LiveBy Loading Icon */
.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

/* LiveBy Login Styles */

.login {
  height: 100%;
  background-color: #DDDDDD;
}
.login .liveby-logo {
  margin: 0 auto;
  display: block;
}
.login .login-form {
  margin: 0 auto;
  display: block;
  background-color: white;
  max-width: 21em;
  min-width: 5em;
  padding: 1em 2em;
  border-radius: 2px;
  box-shadow: 2px 4px 5px #ccc;
}
.login .login-form label, .login .login-form input {
  display: block;
  margin: .5em 0;
}
.login .login-form input {
  width: 90%;
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
}

.login .login-form .btn {
  width: 5em;
  color: #555555;
  font-weight: bold;
  border: none;
  border: solid 1px #DDD;
  background-color: #EEE;
  padding: .5em 1em;
  border-radius: 2px;
  cursor: pointer;
}
.login .error {
  color: #F27574;
}
.login .sent {

}