/**
 * General styles for the page content editor goes here
 */
 .page-editor {
  margin: 1em;
}
.content-width {
  display: inline-block;
}

.page-editor > form {
  display: flex;
  flex-flow: row;
}

.page-editor .column-1 {
  max-width: 40em;
  width: 90%;
}

.page-editor .back-to-pages {
  color: #8f91a0;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-decoration: none;
  position: relative;
  vertical-align: top;
}

.page-editor .back-to-pages::before {
  content: "";
  border: 1px solid #babcc8;
  border-width: 0 2px 2px 0;
  display: inline-block;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  height: 6px;
  width: 6px;
  position: absolute;
  left: -8px;
  bottom: 2px;
}

.page-editor h2 {
  font-size: 1.8333333333em;
  color: #354052;
  font-weight: 400;
  margin: 0.9090909091em 0.9090909091em 0;
 }

.page-editor h3 {
  color: #7F8FA4;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: .5em;
}

.page-editor .bio-editor {
  line-height: 21px;
  font-family: inherit;
  height: 14.75em;
  overflow: auto;
  border-radius: 4px;
}

.page-editor input[type="text"],
.page-editor textarea {
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  padding: .5em;
  width: 30em;
}
.page-editor select {
  font-size: 14px;
}

.tag-editor {
  max-width: 30em;
}

.page-editor .image-alt-container {
  display: inline;
  margin-bottom: 12px;
}

.page-editor .image-alt-container  .image-preview-container {
  padding: 8px;
  border: 1px solid #dfe3e9;
}

.page-editor .image-alt-container .image-preview-container.photo-gallery {
  margin: 0 16px 0 0;
}

.page-editor .alt-text-link {
  padding-left: 11px;
  cursor: pointer;
  font-size: 12px;
  color: blue;
  margin-bottom: 12px;
}

.alt-text-header {
  text-align: right;
}

.alt-text-header a {
  padding: 8px;
  opacity: .6;
  cursor: pointer;
}

.alt-text-body {
  padding: 36px 0 0 0;
}

.alt-text-image img {
  max-width: 400px;
}

.page-editor .live-url {
  display: inline-block;
  color: #8f91a0;
  text-decoration: none;
  font-weight: 300;
  border-radius: 3px;
  background: #fafafa;
  padding: 0.5em;
  border-radius: 4px;
}

.child-pages-editor {
  font-family: inherit;
  width: 100%;
}
.page-editor .child-pages {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  max-height: 24.5em;
  overflow: auto;
}

.child-pages-editor option,
.page-editor .child-pages li {
  padding: .125em;
  font-size: 16px;
}

.page-editor .child-pages a {
  color: #333;
  text-decoration: none;
}

.page-editor .save-button {
  background-color: #20a1d5;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  padding: 1em 2em;
  text-align: center;
  -webkit-transition: background-color .4s,color .4s;
  transition: background-color .4s,color .4s;
  -webkit-appearance: none;
}

.page-editor .save-button:hover,
.page-editor .save-button:focus {
  box-shadow: inset 0 0 10em rgba(255, 255, 255, .15);
}

.page-editor .save-button:disabled {
  background-color: #ddd;
  border: 1px solid #ccc;
}
.page-editor .image-placeholder {
  width: 5.5555555556em;
  height: 3.1388888889em;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  color: #8F91A0;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 100;
}

.page-editor .image-placeholder.allowed {
  border: 3px solid #89CC89;
}

.page-editor .image-placeholder.allowed p {
  color: #89CC89;
}

.page-editor .image-placeholder.rejected {
  border: 3px solid #F36;
}

.page-editor .image-placeholder.rejected p {
  color: #F36;
}

.page-editor .photo-editor {
  font-size: 14px;
  line-height: 21px;
  font-family: inherit;
  width: 100%;
  height: 5em;
  overflow: auto;
  border-radius: 4px;
  padding: .25em;
  margin-bottom: 1em;
}

.page-editor .helptext {
  color: #B0B0B1;
  font-size: 10px;
  margin-bottom: .3em;
  display: block;
}

.page-editor .photo-gallery {
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 0.5em .5em 0;
}
.page-editor .photo-gallery .image-placeholder {
  width: 100%;
  height: 100%;
}

.page-editor input[type='file']:focus + .image-placeholder {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

.page-editor input[type='file'] {
  opacity: 0;
  width: 0px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-editor .image-constraints {
  color: #B0B0B1;
  float: right;
  font-size: 0.8333333333em;
  margin-top: .3em;
}

.page-editor .image-constraints.image-constraints-reached {
  color: red;
}

.page-editor .image-constraints:nth-of-type(2n),
.page-editor .image-constraints:nth-of-type(2n + 1) {
  margin-left: 1em;
}

.page-editor .photo-tagline {
  margin-bottom: .5em;
}

.page-editor .image-preview {
  width: 16.6666666667em;
  height: 9.4166666667em;
  border-radius: 4px;
  transition: background .4s ease-in-out;
}

.page-editor .photo-grid {
  display: flex;
  flex-wrap: wrap;

}

.photo-gallery .image-preview {
  display: block;
  height: 5em;
  width: auto;
}
.page-editor .image-preview-container.photo-gallery {
  width: auto;
  margin: 0 0.5em .5em 0;
  height: unset;
}


.page-editor .image-preview-container {
  position: relative;
  width: 16.6666666667em;
  height: 9.4166666667em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

}

.page-editor .edit-icon path {
  fill: red;
}

.page-editor .edit-icon {
  position: absolute;
  transform: rotate(45deg);
  width: 2em;
  height: 2em;
  opacity: 0;
  transition: opacity .4s ease-in-out;
}

.page-editor .image-preview-container:hover {
  cursor: pointer;
}

.page-editor .image-preview-container:hover .image-preview {
  background-color: rgba(0,0,0,.5);
}

.image-preview-container:hover .edit-icon {
  opacity: 1;
}


.tags-editor {
  font-size: 14px;
  font-family: inherit;
  color: #626279;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: .5em;
}

/*
* React-Select CSS Overrides
*/

.Select-control {
  height: 37px !important;
}

.Select--multi .Select-value {
  background-color: #EEEFF0 !important;
  border: none !important;
  border-radius: 3px !important;
  color: #354052 !important;
  font-size: 14px !important;
  margin-left: 3px !important;
  margin-top: 3px !important;
  font-weight: 600 !important;
}

.Select--multi .Select-value-label {
  padding: 5px 5px 5px 10px !important;
}

.Select--multi .Select-value-icon {
  border-right: none !important;
  color: #C5CBD1 !important;
  float: right !important;
  font-size: 16px !important;
  line-height: 29px !important;
  padding: 0px 10px 0 0px !important;
  vertical-align: middle !important;
}


.page-editor .image-placeholder {
  width: 5.5555555556em;
  height: 3.1388888889em;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  color: #8f91a0;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 100
}

.page-editor .image-placeholder.allowed {
  border: 3px solid #89cc89
}

.page-editor .image-placeholder.allowed p {
  color: #89cc89
}

.page-editor .image-placeholder.rejected {
  border: 3px solid #f36
}

.page-editor .image-placeholder.rejected p {
  color: #f36
}

.page-editor .photo-editor {
  font-size: 14px;
  line-height: 21px;
  font-family: inherit;
  width: 100%;
  height: 5em;
  overflow: auto;
  border-radius: 4px;
  padding: .25em;
  margin-bottom: 1em
}

.page-editor .helptext {
  color: #b0b0b1;
  font-size: 10px;
  margin-bottom: .3em;
  display: block
}

.page-editor .photo-gallery {
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 .5em .5em 0
}

.page-editor .photo-gallery .image-placeholder {
  width: 100%;
  height: 100%
}

.page-editor input[type=file]:focus+.image-placeholder {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

.page-editor input[type=file] {
  opacity: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0
}

.page-editor .image-constraints {
  color: #b0b0b1;
  float: right;
  font-size: .8333333333em;
  margin-top: .3em
}

.page-editor .image-constraints.image-constraints-reached {
  color: red
}

.page-editor .image-constraints:nth-of-type(2n),.page-editor .image-constraints:nth-of-type(odd) {
  margin-left: 1em
}

.page-editor .photo-tagline {
  margin-bottom: .5em
}

.page-editor .image-preview {
  width: 16.6666666667em;
  height: 9.4166666667em;
  border-radius: 4px;
  -webkit-transition: background .4s ease-in-out;
  transition: background .4s ease-in-out
}

.page-editor .photo-grid {
  display: flex;
  flex-wrap: wrap
}

.photo-gallery .image-preview {
  display: block;
  height: 5em;
  width: auto
}

.page-editor .image-preview-container.photo-gallery {
  width: auto;
  margin: 0 .5em .5em 0;
  height: unset
}

.page-editor .image-preview-container {
  position: relative;
  width: 16.6666666667em;
  height: 9.4166666667em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px
}

