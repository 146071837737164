.neighborhood-container {
  margin: 0 0 1.5em 0;
  position: relative;
}

.neighborhood-input {
  border-radius: 100px;
  padding: .75em 0 .75em 3.5em;
  width: 100%;
  font-weight: 300;
}

.neighborhood-container input {
  background-color: #626279;
  border: none;
  color: #fff;
  display: block;
  font-size: 16px;
}

.search-icon {
  position: absolute;
  height: 1.25em;
  width: 1.25em;
  top: .75em;
  left: .75em;
}